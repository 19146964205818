//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import COMMON_METHODS from '@mixins/common-methods.js'

export default {
    mixins: [COMMON_METHODS],
    components: { },
    props: {},
    data(){
        return{
            detailData:{},
            tableData:[],
            listStatus:[
                {value:'待确认',key:'1',type:'info'},
                {value:'待开票',key:'2',type:'warning'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'已结算',key:'4',type:'success'},
            ]
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid');
        }  
    },
    watch:{

    },
    methods: {
        getDetail:function(){
            this.$get(this.$store.getters.orderUrl + '/hcgOrder/'+this.rowguid,{
            }, function(data) {
                this.detailData = data.hcgOrder;
                this.tableData = data.hcgSettOrderInfo;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/hcgSettItem/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //返回
        goBack:function(){
            this.$router.go(-1);
        },
    },
    mounted:function(){
        this.getDetail();   
    }
}
