//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import COMMON_METHODS from '@mixins/common-methods.js'


export default {
    mixins: [COMMON_METHODS],
    props:[],
    components:{},
    data: function(){
        return{
            pgData:{
                kbstarttime:'',
                kbendtime:'',
                orderstarttime:'',
                orderendtime:'',
                platformCode: '',

                title: '',
                starttime: '',
                endtime: '',
                sortname: 'addtime',
                sortorder: 'desc',
                pagesize: 20,
                pageindex: '1',
            },
            tableData:[],
            tableHeight:window.innerHeight - 245,
            listStatus:[
                {value:'未结算',key:'0',type:'danger'},
                {value:'待确认',key:'1',type:'info'},
                {value:'待开票',key:'2',type:'warning'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'已结算',key:'4',type:'success'},
            ],
            //合作单位
            partnerNamelist:[],
            pgExtra:{},
            totalLoading:false,
        }
    }, 
    computed:{
        opentime:{
            get:function(){
                var startTime = this.pgData.kbstarttime || '';
                var endtime = this.pgData.kbendtime || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.kbstarttime = '' ;
                    this.pgData.kbendtime = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.kbstarttime = startTime;
                    this.pgData.kbendtime = endtime;
                }
            },
        },
        ordertime:{
            get:function(){
                var startTime = this.pgData.orderstarttime || '';
                var endtime = this.pgData.orderendtime || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.orderstarttime = '' ;
                    this.pgData.orderendtime = '';
                }else{
                    // var startTime = val[0] || '';
                    // var endtime = val[1] || '';
                    var startTime = val[0] + ' ' + '00:00:00';
                    var endtime = val[1] + ' ' + '23:59:59';
                    this.pgData.orderstarttime = startTime;
                    this.pgData.orderendtime = endtime;
                }
            },
        },
        totalList:function(){
            return [
                {label:'订单合计金额：',num:this.pgExtra.dingDanHeJiJinE},
                {label:'应结算金额：',num:this.pgExtra.yingJieSuanJinE},
                {label:'已结算金额：',num:this.pgExtra.yiJieSuanJinE},
                {label:'待结算金额：',num:this.pgExtra.daiJieSuanJinE},
                {label:'平台收入合计：',num:this.pgExtra.pingTaiShouRuHeJi},
            ]   
        },
    },
    watch:{
  
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
            this.getPgExtra();
        },
        //详情
        listDetail:function(row){
            if(this.getQuery('token')){
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id,
                        token:this.getQuery('token')
                    }
                })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id
                    }
                }) 
            }
        },
        //导出
        exportList:function(){
            var search = toSearch({
                kbstarttime:this.pgData.kbstarttime,
                kbendtime:this.pgData.kbendtime,
                orderstarttime:this.pgData.orderstarttime,
                orderendtime:this.pgData.orderendtime,
                biddername:this.pgData.biddername,
                tenereename:this.pgData.tenereename,
                agencyname:this.pgData.agencyname,
                sectionname:this.pgData.sectionname,
                settStatus:this.pgData.settStatus,
                bidAreaCode:this.pgData.bidAreaCode,
                appId:this.pgData.appId,
                platformCode: this.pgData.platformCode,
                isExport: true,
                token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            });
                window.open('/bhtong/ordermg/hcgOrder' + search);
        },
        afterQuery:function(data,res){
            this.tableHeight = (window.innerHeight - 245);
        },

        //获取合作单位
        getPartnerName:function(){
            this.$get(`${this.getGetters('partnerUrl')}/channelInfo/listSelect`,{},function(data){
                this.partnerNamelist = data;
            })
        },
        getPgExtra:function(){
            this.totalLoading = true;
            this.$get(this.$store.getters.orderUrl + '/hcgOrder/getHcgOrderTotal',this.pgData,function(data){
                this.pgExtra = data;
                this.totalLoading = false;
            })
        },
    },
    mounted() {
        this.getPartnerName();
        this.getPgExtra();
    }
}
